////////////////////////////////////////////////////////////////////////////////////
//      NB: This is a ALPHA BUILD configuration!
////////////////////////////////////////////////////////////////////////////////////

//@ts-ignore
import {AppVersion} from "./config/AppVersion";
//@ts-ignore
import {Environment} from "./config/Environment";
//@ts-ignore
import {LogLevels} from "../../logger/LogLevels";
//@ts-ignore
import {TIME} from "../constants/time";

import {GOALIE_JS__CONFIG, LanguageTag, SenseServerEnvironment} from "@sense-os/goalie-js/dist";

/**
 * Here, we set the central configuration for goalie-js as part of the build config
 */
GOALIE_JS__CONFIG.setEnvironment(SenseServerEnvironment.Alpha);

export class AppConfig {
	/** The default level of logging to use in the app */
	public static readonly logLevel: LogLevels = LogLevels.DEBUG;

	/** Name of the target environment */
	public static readonly env: Environment = Environment.ALPHA;

	/** Version of the application */
	public static readonly version: string = AppVersion.VERSION + "-" + AppConfig.env;

	/**  Data Source Name value to be used with the Sentry SDK */
	public static readonly sentryDSN: string = "https://baece1d3ed6de2a3981c2df1d8b98183@sentry.niceday.dev/4";

	/** URL for long polling to check internet connection */
	public static readonly internetConnectionLongPollingUrl: string =
		"https://storage.googleapis.com/niceday-alpha-media-files/blank.txt";

	/** Inter-window communication whitelisted domains */
	public static readonly IWC_URLs: string[] = [
		"https://alpha.nice-day.nl",
		"https://alpha.niceday.app",
		"https://alpha.niceday.dev",
		"https://fajar.niceday.dev",
		"https://agung.niceday.dev",
		"https://puguh.niceday.dev",
		"https://arif.niceday.dev",
		"https://adzkar.niceday.dev",
	];

	/** The key for Segment Analytics */
	public static readonly SEGMENT_ANALYTICS_KEY: string = "nw2C738DrvvwYBsHh5X2GZAoEnVWoQ9g";

	/** After CALL_TIMEOUT_SECONDS we assume that the other party was away or did not feel like picking the call up. */
	public static readonly CALL_TIMEOUT_SECONDS: number = 25;

	/**
	 * After call, portal might show a form to rate the quality of the call.
	 * This constant here is the minimum duration for a call before portal will show that form.
	 */
	public static readonly MIN_DURATION_FOR_RATE_CALL_FORM_SECONDS: number = 10;

	/** Minimum duration for meeting note confirmation dialog to show up after video calling */
	public static readonly MIN_MEETING_NOTES_DURATION_SECONDS: number = 10;

	/**  In case of doubt which language/locale to use for rendering the UI, the `DEFAULT_LOCALE` will be used. */
	public static readonly DEFAULT_LOCALE: LanguageTag = LanguageTag.NL;

	/** After that many seconds the local user is considered to be inactive in the chat */
	public static readonly ONLINE_TIMEOUT_SECONDS: number = 10;

	public static readonly isDev: boolean = false;
	public static readonly isAlpha: boolean = true;
	public static readonly isBeta: boolean = false;
	public static readonly isProduction: boolean = false;

	/**
	 * Temporary organization for corona scale-up organization migrations
	 * TODO: Remove this when the migration is done.
	 */
	public static readonly CORONA_SCALEUP_ORGANIZATION: number[] = [
		229, // Parnassia
	];

	/**
	 * List of organization ids for researcher users.
	 * If user belongs to this organization, the logged in user will be considered as `Researcher`.
	 */
	public static readonly RESEARCHER_ORGANIZATION_IDS: number[] = [235];

	/** How often the portal checks whether there's a new version released */
	public static readonly VERSION_CHECK_INTERVAL_MS = TIME.MILLISECONDS_IN_MINUTE * 15;

	/** Some features are limited to NiceDay organization */
	public static readonly NICEDAY_ORG_ID = 1;
	public static readonly SOL_ORG_ID = 231;
	public static readonly SOLO_THERAPIST_ORG = 243; // Placeholder for future value
	public static readonly NDT_ORG = -1;
	public static readonly SUPPORT_ORG = 246;

	/** Base URL for NiceDay app's universal link. */
	public static readonly APP_BASE_URL = "https://get-alpha.niceday.app";

	/** Key for product fruits workspace code */
	public static readonly PRODUCTFRUITS_WORKSPACE_KEY: string = "D80jtJ8RALlvclQm";
	/** Key for freshchat */
	public static readonly FRESHCHAT_KEY: string = "3fea453b-2132-4edd-9ac8-7453c8fd2615";

	/** product fruit survey id */
	public static readonly PRESET_SURVEY_ID = "6b307303-4bfe-4d5a-8111-983197681f47";
}
